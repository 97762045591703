import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import RichText from '~components/RichText'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import ModuleStyles from '~styles/ModuleStyles'
import styled from '@emotion/styled'
import { mobile } from '~styles/global'
import Section from '~components/Section'

const Page = ({ data }) => {
	const { title, subtitle, content } = data.sanityPage
	return(
		<>
			<Seo 
				title={title}
				metaTitle={content?.seo?.metaTitle}
				description={content?.seo?.metaDescription}
				image={content?.seo?.socialImage}
			/>
			<Header>
				{subtitle &&
					<PreHeading>{subtitle}</PreHeading>
				}
				<Heading className='large'>{title}</Heading>
			</Header>
			<ModuleStyles>
				<RichText content={content.text}/>
			</ModuleStyles>
			<DataViewer data={content} name="content"/>
		</>
	)
}

const Header = styled(Section)`
	padding-top: 120px;
	margin-bottom: -40px;
	${mobile}{
		padding-top: 50px;
		margin-bottom: 0;
	}
`
const PreHeading = styled.h3`
	grid-column: 2/12;
	text-align: center;
	margin-bottom: 38px;
	${mobile}{
		grid-column: span 6;
		margin-bottom: 20px;
	}
`
const Heading = styled.h1`
	grid-column: 3/11;
	text-align: center;
	${mobile}{
		grid-column: span 6;
	}
`

Page.propTypes = {
	data: PropTypes.object,
}

export const query = graphql`
  query PageQuery($slug: String) {
    sanityPage(slug: {current: {eq: $slug}}) {
      title
			subtitle
      content {
        text: _rawText(resolveReferences: {maxDepth: 4})
      }
    }
  }
`

export default Page