import { mobile, tablet } from '~styles/global'
import styled from '@emotion/styled'

const ModuleStyles = styled.div`
	& > div {
		margin-top: 152px;
		${mobile}{
			margin-top: 62px;
		}
	}
	& > div > p,
	& > div > h1,
	& > div > ol {
    width: calc(52% - 10px);
    margin-left: auto;
    margin-right: auto;
    max-width: 736px;
    padding: 0 40px;
    box-sizing: border-box;
    ${mobile}{
      width: 100%;
      max-width: 100%;
      padding: 0 20px;
    }
	}
	& > div > p{
		margin: 40px auto;
		${mobile}{
			margin: 30px auto;
		}
	}
	& > div > h1{
		margin: 164px auto 40px;
		text-align: center;
		${mobile}{
			margin: 59px auto 30px;
		}
	}
	& > div > ol {
		max-width: 480px;
		margin: 150px auto 150px;
		list-style-type: none;
		font-size: 16px;
		line-height: 21px;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		text-align: center;
		${tablet}{
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 0.03em;
		}
		${mobile}{
			margin: 104px auto 104px;
		}
	}
	ol > li{
		margin-bottom: 90px;
		${mobile}{
			margin-bottom: 66px;
			padding: 0 3%;
		}
		&:before{
			content: "①";
			display: block;
			font-family: "Monument Grotesk Semi Mono", sans-serif;
			font-size: 28px;
			margin-bottom: 26px;
			${tablet}{
				font-size: 21px
			}
			${mobile}{
				margin-bottom: 23px;
			}
		}
  	&:nth-of-type(2):before{
  		content: "②"
  	}
  	&:nth-of-type(3):before{
  		content: "③"
  	}
  	&:nth-of-type(4):before{
  		content: "④"
  	}
  	&:nth-of-type(5):before{
  		content: "⑤"
  	}
  	&:nth-of-type(6):before{
  		content: "⑥"
  	}
  	&:nth-of-type(7):before{
  		content: "⑦"
  	}
  	&:nth-of-type(8):before{
  		content: "⑧"
  	}
  	&:nth-of-type(9):before{
  		content: "⑨"
  	}
  	&:nth-of-type(10):before{
  		content: "⑩"
  	}
  	&:nth-of-type(11):before{
  		content: "⑪"
  	}
  	&:nth-of-type(12):before{
  		content: "⑫"
  	}
  	&:nth-of-type(13):before{
  		content: "⑬"
  	}
  }
`

export default ModuleStyles